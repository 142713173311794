import i18next from 'i18next';
import jqueryI18next from 'jquery-i18next';
import LngDetector from 'i18next-browser-languagedetector';

export default class TranslateClass {
  /**
   * @param {string} defaultLanguage
   * @param {Object} languageOptions
   */
  constructor(defaultLanguage, languageOptions) {
    this.translation = i18next;
    this.defaultLanguage = defaultLanguage;
    this.languageOptions = languageOptions;
  }

  init() {
    return new Promise(resolve => {
      this.translation
        .use(LngDetector)
        .init({
          fallbackLng: this.defaultLanguage,
          resources: this.languageOptions,
        })
        .then((translate) => {
          jqueryI18next.init(i18next, $);
          resolve(translate);
        })
    });
  }

  /**
   * @param {string} lng
   */
  changeLanguage(lng) {
    return this.translation
      .changeLanguage(lng)
      .then(translate => translate);
  }
}
