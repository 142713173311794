require('normalize.css/normalize.css');
require('./../static/styles/styles.scss');

import Translate from './../static/js/translate'
import spanishTranslation from './../static/js/translate/spanishTranslation.json'
import englishTranslation from './../static/js/translate/englishTranslation.json'
import smoothScroll from 'smoothscroll';


$(document).ready(function () {
  $('.menu-item').on('click', function (e) {
    e.preventDefault();
    const hash = $(this).attr('href');
    const element = document.querySelector(hash);
    smoothScroll(element);
  });

  $.each(['home', 'products', 'about-us', 'contact'], function (i, classname) {
    var $elements = $('#' + classname)

    $elements.each(function () {
      new Waypoint({
        element: this,
        handler: function (direction) {
          const el = $(this.element)
          let wayID = 'down' === direction ? el.attr('id') : el.prev().attr('id');
          $('.active').removeClass('active');
          $('.header__menu-link[href="#' + wayID + '"]').parents('.header__menu-item').addClass('active');
        },
        offset: '15%',
      })
    })
  });

  const $menuIcon = $('#menu');
  const $menuList = $('.header__menu-list');
  const player = document.querySelector('.player');
  const video = player.querySelector('.viewer');
  const toggle = player.querySelector('.toggle');


  /* Build out functions */
  function togglePlay() {
    const method = video.paused ? 'play' : 'pause';
    video[method]();
  }

  function updateButton() {
    const icon = $('#toggle-icon');
    icon.removeAttr('class');
    if (this.paused) {
      icon.addClass('fa fa-play')
      $('.video__button__wrapper').show();
    } else {
      icon.addClass('fa fa-pause')
    }
  }

  /* Hook up the event listeners */
  video.addEventListener('click', togglePlay);
  video.addEventListener('play', updateButton);
  video.addEventListener('pause', updateButton);
  toggle.addEventListener('click', togglePlay);

  $(player).on('mouseover', () => {
    if (!video.paused) {
      $('.video__button__wrapper').show();
    }
  });

  $(player).on('mouseout', () => {
    if (!video.paused) {
      $('.video__button__wrapper').hide();
    }
  });



  $menuIcon.on('click', () => {
    $menuList.stop(true, true).animate({
      opacity: 'toggle',
      height: 'toggle'
    });
  })

  const updateContent = () => {
    $('body').localize();
  }

  const translate = new Translate('en', {
    es: spanishTranslation,
    en: englishTranslation,
  });

  translate.init()
    .then(() => {
      updateContent();
    });

  const changeLanguageEn = () => {
    translate.changeLanguage('en')
      .then(() => {
        updateContent();
      });
  }

  const changeLanguageEs = () => {
    translate.changeLanguage('es')
      .then(() => {
        updateContent();
      });
  }

  $('.spanish').on('click', function (e) {
    e.preventDefault();
    changeLanguageEs();
  });

  $('.english').on('click', function (e) {
    e.preventDefault();
    changeLanguageEn();
  });

  $('.autoplay').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnFocus: false,
    pauseOnHover: false
  });

  function after_form_submitted(data) {
    if (data.result == 'success') {
      $('form#reused_form').trigger("reset");
      $('#success_message').show();
      $('#error_message').hide();
    }
    else {
      $('#error_message').append('<ul></ul>');

      jQuery.each(data.errors, function (key, val) {
        $('#error_message ul').append('<li>' + key + ':' + val + '</li>');
      });
      $('#success_message').hide();
      $('#error_message').show();

    }//else

    //reverse the response on the button
    $('button[type="button"]').each(function () {
      var $btn = $(this);
      var $label = $btn.attr('orig_label');
      if ($label) {
        $btn.prop('type', 'submit');
        $btn.text($label);
        $btn.attr('orig_label', '');
      }
    });
  }

  $('#reused_form').submit(function (e) {
    e.preventDefault();

    var $form = $(this);
    //show some response on the button
    $('button[type="submit"]').each(function () {
      var $btn = $(this);
      $btn.prop('type', 'button');
      $btn.attr('orig_label', $btn.text());
      $btn.text('Sending ...');
    });


    $.ajax({
      type: "POST",
      url: '/php/handler.php',
      data: $form.serialize(),
      success: after_form_submitted,
      dataType: 'json'
    });

  });

});
